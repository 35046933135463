/*Home Override*/
/*template from Kooka UK*/
.main {
    padding: 0;
}
.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.page-type-home .xm-cms-home .main.container, .page-type-home .xm-cms-home .main.container > .col-main.grid12-12.user-grid-content, .page-type-home .xm-cms-home .main.container > .col-main.grid12-12.user-grid-content .grid12-12.col-main .col-main.grid-full.in-col1, 
.page-type-home .isPublic .xm-cms-home .main.container, .page-type-home .isPublic .xm-cms-home .main.container > .col-main.grid12-12.user-grid-content, .page-type-home .isPublic .xm-cms-home .main.container > .col-main.grid12-12.user-grid-content .grid12-12.col-main .col-main.grid-full.in-col1 {
    max-width: none;
    width: 100%;
    margin: 0;
    padding: 0;
    display: block;
    float: none;
    clear: both;
    margin-left: -1%;
}
.homepage-category p {
    text-align: center;
    font-weight: 300;
    font-size: 18px;
}
.homepage-category div:hover {
    text-align: center;
    font-weight: 300;
    font-size: 18px;
    padding-bottom: 6%;
    border-bottom: 10px solid #c7804d;
}
.homepage-category {
height: 250px;
}

/*Best Seller hot deal modules*/
.best-seller .products-grid .product-image-wrapper {
    width: 60%;
}
.best-seller .owl-carousel .owl-item .product-name a, .xm-owl-carousel .item .product-name a {
    white-space: normal;
    font-weight: 300;
}
.best-seller .owl-carousel.owl-drag .owl-item {
    height: auto !important;
}
.best-seller .itemslider .item {
    padding-left: 10%;
    padding-right: 10%;
}
.best-seller .products-grid .price-box {
    margin-top: 20%;
    font-size: 1em;
    margin-bottom: 0;
}
.best-seller .products-grid .product-name, .products-list .product-name, h3.product-name {
height: 50px;
}