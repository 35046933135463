@import 'https://fonts.googleapis.com/css?family=Montserrat:400,700,800,900';


/* ================================================ */
/* Init */
/* ================================================ */
body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #1B262C;
}

a {
    -moz-transition: all .8s ease;
    -webkit-transition: all .8s ease;
    -o-transition: all .8s ease;
    transition: all .8s ease;
    color: #444;
}

a > i {
    font-style: normal;
}

.wrapper {
    border-top: none;
}

.xm-grid-header > .container {
    width: 100%;
    margin: 0;
    padding: 0;
}

.main {
    padding: 30px 0 0;
}

.container {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.hidden {
    display: none !important;
}

button.button {
    -webkit-border-fit: lines;
    overflow: visible;
    width: auto;
    border: 0;
    padding: 0;
    margin: 0;
    background: transparent;
    cursor: pointer;
}

button.button span {
    text-transform: uppercase;
    /*overwritten in category.css*/
    /*border-radius: 30px;
    background-color: #f5f5f5;
    color: #333;*/
    float: left;
    display: block;
    padding: 0;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -moz-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -webkit-transition: color .3s ease-in-out, background-color .3s ease-in-out;
    -o-transition: color .3s ease-in-out, background-color .3s ease-in-out;
}
/*overwritten in category.css*/
/*.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span {
    background-color: #93d600;
    color: #fff;
}*/

button.button span span {
    font-family: 'Montserrat', sans-serif;
}

.buttons-set button.button {
    margin-left: 0;
    margin-right: 5px;
    margin-bottom: 5px;
}
/*Overwritten in cart.css*/
/*.button.btn-inline span, .button.btn-checkout span {
    background-color: #1b262c;
    color: #fff;
}*/
button.add-cart-button {
 margin-right: 15px !important;
}

@media only screen and (min-width: 961px) {
    .show-below-960 {
        display: none !important;
    }
}

@media only screen and (max-width: 959px) {
    .hide-below-960 {
        display: none !important;
    }
}

/* ================================================ */
/* Font and Colours */
/* ================================================ */
.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}
/*Overwritten in header.css*/
/*#root-wrapper a:hover span.fa:before, .header-top a:hover, #nav .nav-panel--dropdown a:hover, p.required, .product-options dt label.required em, .form-list label.required em, a:hover, .vertnav li.current > a, .header .dropdown-menu a:hover, .header .open > .dropdown-toggle.cover > div a:hover, .header .form-search .search-autocomplete li:hover {
    color: #93D600;
}*/

.header-top-container {
    text-transform: uppercase;
}
/*Overwritten in header.css*/
/*nav.nav-container,
.header-primary-container,
.header-top-container {
    background-color: #1B262C;
}*/

.main-container {
    background-color: #FFF;
    padding: 0 !important;
}
/*Overwritten in category.css*/
/*.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
    color: #93D600;
}*/

/* ================================================ */
/* Header */
/* ================================================ */
header#header.floating {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: none;
}

.header .userCode,
.header .userName,
.header .userSwitch,
.header .userSwitch a,
.header .welcome-msg {
    font-weight: 700;
    font-size: 11px;
    color: #aaa !important;
    margin: 0 !important;
}

.header .userSwitch {
    margin-left: 10px !important;
}

.header .userSwitch a,
.header .userSwitch a:hover {
    border-bottom: 1px solid #aaa;
}

.header-container {
    position: relative;
}

.header-primary {
    padding: 0;
    position: relative;
}

.header-top-container {
    border-bottom: #454e53 solid 1px;
}
/*
.header-top, .header .dropdown {
    line-height: 0;
}
*/
/*Overwritten in header.css*/
/*nav.nav-container, .header-primary-container, .header-top-container {
    background-color: #1B262C;
}*/


.item-welcome-msg {
    margin-left: 0 !important;
}

header#header .top-links > .links > li > a, header#header .links-container > .links > .company > dl.company-links dt a {
    color: #aaa !important;
    border: none;
    background-image: none;
}

header#header .top-links > .links > li > a:hover {
    color: #fff !important;
}

.user-menu {
    margin: 0;
}

#mini-cart .feature-icon-hover a {
    color: #aaa;
}

.links > li > a {
    padding: 0 17px;
    color: #fff;
}
/*overwritten in header.css*/
/*.links > li > a:hover {
    background-color: #93d600;
    color: #fff;
}*/

.dropdown-toggle * {
    display: inline-block;
}

.welcome-msg, .userCode, .userName, .userSwitch > a {
    color: #fff;
}
/* ================================================ */
/* Menu */
/* ================================================ */
.nav.has-outline>.grid-full {
    margin-top: 0;
    margin-bottom: 0;
}
#nav {
    z-index: 1000;
    position: relative;
}

#nav, .mobnav-trigger {
    border-bottom: none;
    margin: 0;
}

.mobnav-trigger,
.mobnav-trigger.active,
.mobnav-trigger:hover {
    background-color: transparent;
}

.nav-regular {
    background-color: transparent;
}

#nav.nav-mobile ul.level0 .nav-item.current > a, #nav.nav-mobile ul.level0 .nav-item > a:hover, .cms-index-index .nav-regular .nav-item--home > a, .nav-mobile .nav-item.level0.current > a, .nav-mobile .nav-item.level0 > a:hover, .nav-regular .nav-item.level0.active > a, .nav-regular .nav-item.level0:hover > a {
    background-color: transparent;
}
/*Overwritten in header.css*/
/*.nav-container a, .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a, .mobnav-trigger a {
    color: #fff;
}
.nav-regular .nav-item.level0:hover > a, .nav-mobile .nav-item.level0 > a:hover {
    color: #93D600 !important;
    background-color: #fff;
}
.nav-item.active {
    background-color: #fff;
}
.nav-item.active > a,
.module-sub-categories-title.active{
    color: #93D600 !important;
}
.nav-item.active > a .caret {
    border-top-color: #93D600 !important;
}*/

.main-font, h1, h2, h3, h4, h5, h6, .section-title, .products-list .product-name, .products-grid .product-name, h3.product-name, .feature .heading, .accordion .heading, .nav-regular li.level0 > a, .nav-regular .nav-submenu--mega > li > a, .mobnav-trigger, .nav-mobile li.level0 > a, .nav-mobile li.level1 > a, .nav-mobile li.level2 > a, .block .block-title, #opc-login h3, .box-account .box-head h2, .order-items h2.table-caption, .order-items h2.sub-title, .order-items .order-comments h2, .product-view .box-reviews dt .heading, .gen-tabs .tabs a, .footer .heading, #subscribe-form label, .caption, .heading {
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
}
h3 {
    font-size: 16px;
}
/*Overwritten in category.css*/
/*.products-grid .product-name, .products-list .product-name, h3.product-name {
    font-size: 14px;
}*/

.block .block-title {
    border-bottom: none;
    position: relative;
}

#pageTitle .mh-filters-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
}
#pageTitle h1 {
    margin-bottom: 0;
}

.block .block-title .menu-icon {
    padding: 0 10px !important;
}

.sorter .amount {
    color: #aaa;
}

.nav-regular li.level0 > a {
    line-height: 70px;
}

.nav-regular li.level0 > a > span {
    display: inline-block;
    white-space: nowrap;
}

#nav .nav-item.level0.parent > a .caret {
    border-top-color: #fff;
}
/*Overwritten in header.css*/
/*#nav .nav-item.level0.parent:hover > a .caret {
    border-top-color: #93D600;
}*/
/*Overwritten in header.css*/
/*.nav-regular .mega > .nav-panel--dropdown, .nav-regular li.level0 > .nav-panel--dropdown {
    border-top: none;
    width: 100vw !important;
    margin-left: -5%;
}*/

.nav-regular li.level0 > .nav-submenu.nav-panel--dropdown {
    width: 16em !important;
}

.nav-mobile.opt-sb2 li.level0>div>div>.nav-block {
    padding: 0 10px;
}

.nav-mobile .opener:before {
    content: "\f107" !important;
    padding: 10px 10px 10px 20px !important;
}

.nav-mobile .opener.active:before, .nav-mobile li.active > .opener:before {
    content: "\f106" !important;
}

.nav-search.fly-out {
    display: none;
}

.nav-search .form-search .button-close {
    display: none;
}

.form-search .button i {
    font-style: normal;
}
/*Overwritten in header.css*/
/*#breadcrumbs li {
    display: none;
}*/

.breadcrumbs .nav-filter {
    float: right;
    font-size: 16px;
    font-weight: 700;
}
/*Overwritten in header.css*/
/*#breadcrumbs a {
    text-transform: uppercase;
}*/
#filter_list_box dt {
    font-weight: 700;
    font-size: 14px;
    margin-top: 10px;
}
.page-title.category-title {
    border: none;
}
#pageTitle {
    display: none;
    position: relative;
}
.category-products .toolbar {
    border: none;
    width: 100%;
    text-align: right;
}
.category-products .toolbar .sorter {
    display: inline-block;
}
.category-products-grid.hover-effect .item:hover, .products-list.hover-effect .item:hover {
    margin-left: 0;
    margin-right: 0;
    box-shadow: none;
    z-index: 0;
}
.category-products-grid.hover-effect .item:hover {
    padding-left: 1%;
    padding-right: 1%;
}
.products-list.hover-effect .item:hover {
    padding-left: 0;
    padding-right: 0;
}
.sidebar a, .accordion-style1 li a {
    text-transform: uppercase;
}
.mini-products-list li {
    list-style: none;
}
.breadcrumbs li {
    display: inline-block;
    vertical-align: top;
}/*Overwritten in productdetail.css*/
/*.product-shop h1 {
    font-size: 20px;
}*/
.product-shop .qty-wrapper label {
    display: none;
}
@media only screen and (max-width: 960px) {
    #pageTitle {
        display: block;
    }
    .page-title.category-title {
        display: none;
    }
    .block .block-title {
        display: none;
    }
    
    .form-list .field,
    .form-list input.input-text,
    .form-list select,
    .progress, div[class^=strength_meter_passwd] {
        width: 100%;
    }
   
    /*.nav-item.active > a,
    .module-sub-categories-title.active{
        color: #93D600 !important;
        background-color: #1b262c !important;
    }*/
    .nav-item.active > .opener {
        color: #fff !important;
    }
    .xm-grid-product .grid-left,
    .xm-grid-product .grid-right {
        width: 100% !important;
    }
    .product-shop h1,
    .product-shop .product-type-data {
        text-align: center !important;
    }
    .grid-col2-sidebar,
    .product-search-container .sidebar-category,
    .product-search-container .has-sidebar.grid-col2-main {
        width: 100%;
    }
    .details-main-container .breadcrumbs {
        display: none;
    }
    .toolbar .sorter .amount, .sorter .sort-by {
        display: inline-block !important;
    }
    .sorter .view-mode {
        float: right;
    }
    .links>li>a {
        padding: 0 10px;
    }
    .product-shop {
        margin-top: 20px;
    }
    .sidebar-category .block-content {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
    }
    .sidebar-category .accordion{
        overflow-x: scroll;
        width: max-content;
    }
    .mm-panels .sidebar-category .accordion {
        width: 100% !important;
    }
    .sidebar-category .accordion li{
        display: inline-block;
        width: auto !important;
    }
    .mm-panels .sidebar-category .accordion li{
        display: block;
        width: 100% !important;
        text-align: left;
    }
    .grid-full, .grid12-12 {
        overflow: auto;
    }
    .nav-item .header-top-container {
        background-color: #fff;
    }
    .search-wrapper-mobile {
        width: 36px !important;
        height: 36px;
        margin: 0 !important;
        padding: 0;
        float: none;
        vertical-align: top;
    }
    .search-wrapper-mobile .form-search input {
        display: none;
    }
    .mobnav-trigger-wrapper {
        display: inline-block !important;
        vertical-align: top;
        float: none !important;
        padding: 10px .5em !important;
    }
    .mobnav-trigger {
        padding: 0;
    }
    .mobnav-trigger > span:last-child {
        display: none;
    }
    .mobnav-trigger .trigger-icon {
        padding-top: 0;
        margin: 0;
    }
    .mobnav-trigger .trigger-icon .line {
        background-color: #fff;
    }
    .search-wrapper-centered {
        float: none !important;
        display: inline-block !important;
    }
    #mini-cart .feature-icon-hover .hide-below-960,
    #mini-cart .feature-icon-hover .empty,
    nav .feature-icon-hover .hide-below-960,
    nav .feature-icon-hover .empty{
        display: none;
    }
    nav .feature-icon-hover {
        padding: 0 !important;
    }
    nav .feature-icon-hover .caret {
        display: none;
    }
    nav.nav {
        text-align: right;
        height: 36px;
    }
    nav.nav .header-top {
        margin: 0;
        padding: 0;
        width: 100%;
        display: block !important;
    }
    .nav-mobile {
        text-align: left;
    }
    .nav-logo, .nav-cart, .nav-search {
        display: inline-block;
        vertical-align: top;
    }
    .nav-logo {
        float: left;
        width: 200px;
        margin-top: 5px;
    }
    .nav-cart {
        height: 36px;
    }
    .nav-cart .fa-shopping-cart {
        /*font-size: 1.5em !important;*/
        font-style: normal;
    }
    .nav-cart .hide-below-960 {
        visibility: hidden;
        position: relative;
        width: 0;
        height: 0;
        display: block !important;
    }
    .nav-cart .hide-below-960 .cart-total {
        visibility: visible;
        position: absolute;
        left: 25px;
        top: -35px;
        border: none;
        background-color: #93d600;
        border-radius: 50%;
        color: #fff;
        width: 17px;
        height: 17px;
        text-align: center;
        font-size: 9px;
        line-height: 17px;
    }
    .nav-cart .product-details a {
        color: #888;
    }
    .nav-cart .btn-remove,
    .nav-cart .btn-edit {
        float: right;
    }
    .nav-cart .empty, .nav-cart .caret {
        display: none;
    }
    
    .nav-search .input-text {
        display: none;
    }
    /*
    .nav-search .button {
        height: 36px;
    }
     */
    .cart-mobile {
        display: inline-block !important;
        vertical-align: top;
    }
    .nav-item .header-top {
        display: block !important; 
    }
    .header-top .item {
        display: block;
    }
    .nav-mobile .nav-block {
        display: initial;
    }
    .nav-mobile .nav-block,
    .nav-mobile .nav-block > .grid12-12 {
        padding: 0 !important;
        margin: 0 !important;
        width: 100%;
    }
    .nav-mobile .nav-block > .grid12-12 {
        width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
    .module-sub-categories .nav-item {
        width: 100% !important;
    }
    .module-sub-categories .nav-item > a {
        padding: 15px 54px 15px 20px !important;
        text-transform: uppercase !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    .module-sub-categories .nav-item .page-item {
        padding: 0 !important;
    }
    .module-sub-categories .nav-item .page-item > a {
        padding: 15px 10px 15px 40px !important;
        display: block;
        text-transform: uppercase;
    }
    .acco .module-sub-categories div.nav-item ul li {
        padding-left: 0 !important;
    }
    .nav-mobile .nav-panel-inner .opener {
        position: absolute;
        top: 15px;
        right: 15px;
    }
    .nav-mobile li.nav-item {
        font-size: 14px;
        background-color: #f7f7f7;
    }
    .nav-mobile .nav-item.parent a {
        background-color: transparent;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        font-weight: 400;
    }
    .header-container .col-main {
        margin-bottom: 0;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
    }
    .nav-item.show-below-960 {
        padding: 10px !important;
    }
    .nav-item.show-below-960 > div {
        display: block !important;
        padding: 0.3em 1em;
    }
    .nav-search.fly-out {
        position: fixed;
        display: block !important;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 9999;
        width: 100% !important;
        background-color: #fff;
    }
    .nav-search.fly-out .form-search {
        position: relative;
        padding: 10px;
    }
    .nav-search.fly-out .form-search .input-text {
        width: 100%;
        max-width: 100%;
        display: block;
        border: none;
        padding-right: 70px;
    }
    .nav-search.fly-out .form-search .button {
        color: #aaa;
        top: 10px;
        right: 10px;
        position: absolute;
        margin: 0;
        padding: 0;
        font-size: inherit;
    }
    .nav-search.fly-out .form-search .button .fa {
        margin: 0 !important;
    }
    .nav-search.fly-out .form-search .button-search {
        right: 45px;
    }
    .nav-search.fly-out #inline-search-results {
        top: 50px;
    }
    .nav-search.fly-out .form-search .button-close {
        display: block;
    }
}
@media (max-width: 600px) {
    #my-menu {
        display: none !important;
    }
}
/* ================================================ */
/* Content: Logo and Search  */
/* ================================================ */
.header .logo-wrapper {
    text-align: center;
    margin: 10px 0;
}

.header .logo-wrapper a.logo {
    display: block;
}
/*Overwritten in header.css*/
/*.header .logo {
    max-width: 330px;
}*/

.search-wrapper-centered {
    margin-top: 18px;
    margin-bottom: 7px;
}

.search-wrapper-centered .form-search {
    margin: 0 auto;
}

.search-wrapper-centered .form-search label {
    display: none;
}

/*Overwritten in header.css*/
/*.form-search .button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 1.375em;
    color: #fff;
    padding: .5em;
}*/

@media only screen and (min-width: 960px) {
    /*overwritten in header.css*/
    /*.user-link-wrapper {
        float: right;
    }*/
    /*.search-wrapper, .logo-wrapper {
        margin-top: 15px;
        text-align: center;
    }*/
    .search-wrapper {
        margin-right: 20px;
    }
    /*overwrite- styling not needed*/
    /*.search-wrapper .form-search .input-text {
        width: 36px;
        padding-right: 36px !important;
        background-color: transparent;
        z-index: 1;
        position: relative;
        color: #fff !important;
        float: right;
        border: none;
    }*/
/*Overwritten in header.css*/
    /*.search-wrapper .form-search .input-text:hover, .search-wrapper .form-search .input-text:focus {
        width: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        color: #fff;
        transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -webkit-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
    }*/
}

@media only screen and (min-width: 768px) {
    .logo-wrapper > a {
        margin: 0 auto !important;
    }
}

/* ================================================ */
/* Content: Product Listing  */
/* ================================================ */

/* ================================================ */
/* Content: Product Detail  */
/* ================================================ */
/*Overwritten in productdetail.css*/
/*.product-shop .price-box .regular-price .price {
    font-size: 2em;
}*/

.matrix-table .price-box .regular-price .price {
    font-size: 1em;
}

.product-shop .btn-cart span span {
    padding: 5px 40px;
    font-size: 1.1666em;
}

.product-shop .z4 {
    background-color: #93D600 !important;
}

.img-box-style1 .product-image {
    border: none;
    padding: 0;
}

.product-shop h1 {
    color: #1B262C;
}
.product-shop ul {
    list-style: disc inside;
}

.product-shop .product-options dd {
    padding-left: 0;
}
/*Overwritten in category.css*/
/*.price-box .regular-price .price, .price-box .minimal-price .price, .price-box-bundle .full-product-price .price {
    color: #93D600;
    font-weight: 400;
    letter-spacing: 0.5px;
    font-family: 'Montserrat', sans-serif;
}*/

.product-options dt label, .product-shop .qty-wrapper label {
    font-size: 1.3em;
}

.product-shop .qty-wrapper {
    margin-top: 5px;
    margin-bottom: 15px;
}

.product-shop .qty-wrapper input {
    font-size: 1.2em;
    min-width: 150px;
    width: 100% !important;
}

.product-shop button.add-cart-button {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0;
}
/*overwritten in productdetail.css*/
/*.product-shop button.add-cart-button span {
    width: 100%;
    padding: 0;
    color: #fff;
    background-color: #111;
}*/

.product-shop button.add-cart-button:hover span,
.product-shop button.add-cart-button span:hover {
    background-color: #5c5c5c !important;
}

.product-shop .short-description {
    font-size: 16px;
    line-height: 1.75;
    padding-top: 32px;
    padding-bottom: 24px;
    font-weight: 100;
}

.product-shop ul li {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop p {
    font-size: 16px;
    line-height: 1.75;
    font-weight: 100;
}

.product-shop .product-type-data {
    text-align: right;
}

.variations .swatches-con.list, .variations .swatchesCon.list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.variations .swatches-con.list .swatch, .variations .swatchesCon.list .swatch {
    display: inline-flex !important;
}

.variations .swatches-con.list .swatch .text, .variations .swatchesCon.list .swatch .text {
    height: 46px !important;
    line-height: 46px !important;
    width: 100%;
    border-radius: 4px;
    font-size: 1.3em !important;
    overflow: hidden;
}

.variations .swatches-con.list .swatch.selected .text {
    border: 1px solid #000 !important;
    background-color: #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    color: #fff !important;
    font-size: 1.3em !important;
}

.variations .swatches-con.list .swatch.available:hover .text {
    border: 1px solid #000 !important;
    height: 46px !important;
    line-height: 46px !important;
    font-size: 1.3em !important;
}

/* ================================================ */
/* Content: Others  */
/* ================================================ */
.promo-header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: #fff;
    background-color: #93D600;
    padding-top: 11px;
    font-size: 1em;
    letter-spacing: 0.5px;
}

.homepage-carousel-caption {
    margin: 0;
    position: absolute;
    bottom: 7%;
    left: 7%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: left;
    font-weight: 400;
    text-transform: none;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    color: #fff;
}

.homepage-carousel-caption h2 {
    font-size: 3em;
    font-weight: 700;
    text-align: left;
    margin: 0;
    line-height: 1.5em;
    text-transform: uppercase;
}

.homepage-button {
    padding: 20px;
    font-family: 'Montserrat';
    float: left;
    background-color: #93d600;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    border: none;
    border-radius: 30px;
    -webkit-transition: background-color 0.15s ease-out;
    -moz-transition: background-color 0.15s ease-out;
    -o-transition: background-color 0.15s ease-out;
    transition: background-color 0.15s ease-out;
}

.homepage-button:hover {
    background-color: #1B262C;
    color: #fff;
}

.fa-chevron-right:before {
    content: "\f054";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
}

.slider-arrows2 .owl-controls div {
    background-color: transparent;
}

.page-content h2 {
    font-size: 2em;
}

/*Newsletter Module Home page*/
#subscribe-form form {
    display: block;
    text-align: center;
    background-color: #93D600;
    text-transform: capitalize;
}

#subscribe-form {
    margin-top: 55px;
    padding: 1%;
    padding-bottom: 30px;
}

.section-title.padding-right {
    padding-right: 0;
}

.newsletter-label-input_email {
    display: none;
}

.newsletter-wrapper {
    background-color: #93D600;
    width: 100%;
    margin-bottom: -20px;
}

.newsletter .section-title {
    border-bottom: none;
    background-color: #93D600;
    text-align: center;
    font-size: 1.5em;
    color: #fff;
    letter-spacing: 0.5px;
}

.newsletter-wrapper .text_title {
    clear: both;
    display: block;
    padding: 10px;
    color: #fff;
}

.newsletter .inputs {
    display: block;
    width: 50%;
    margin: 0 auto;
}

#subscribe-form .input-box {
    float: left;
    margin: 0 1%;
}

#subscribe-form .input-text, #subscribe-form .button {
    font-family: 'Montserrat', sans-serif;
}

.input-box.newsletter-field-input_email {
    width: 78%;
}

.input-text.required-entry.validate-input_email {
    width: 100% !important;
}

.button.btn-inline.newsletter-button {
    width: 20%;
    margin: 0;
    border-radius: 30px;
}

.button.button.btn-inline.newsletter-button span span:hover {
    background-color: #fff;
    color: #1d262b;
    border-radius: 30px;
}

.newsletter button.button span {
    width: 100%;
    text-transform: uppercase;
    border-radius: 30px;
}

.block-account .block-title {
    background: none;
}

.product-options.border-out {
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
}

.product-options.border-out:after {
    content: 'Please select the options';
    color: red;
    font-size: 1.2em;
}
/*overwritten in productdetail.css*/
/*.add-cart-fixed {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 50%;
    background-color: #111;
    color: #fff;
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}*/

.add-cart-fixed span {
    height: 45px;
    line-height: 45px;
    font-size: 1.1666em;
    text-transform: uppercase;
}

.form-list .field {
    position: relative;
}

/* ================================================ */
/* Footer */
/* ================================================ */
/*Footer*/
ul.bullet li {
    background: none;
    padding-left: 0px;
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.5px;
}

#footer ul li a {
    text-decoration: none;
    line-height: 1.75em;
    color: #b3b3b3;
    font-size: 1em;
    font-weight: 400;
}

#footer ul li a:hover {
    border-bottom: 1px solid #b3b3b3;
}

h6.block-title.heading {
    color: #1d262b;
    font-size: 1em;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer-top-container {
    background-color: #f4f4f4;
    margin-top: 30px;
}

.footer-top-container img {
    opacity: 1;
    -webkit-transition: opacity 0.15s ease-out;
    -moz-transition: opacity 0.15s ease-out;
    -o-transition: opacity 0.15s ease-out;
    transition: opacity 0.15s ease-out;
}

.footer-top-container img.main-partner-logo {
    margin: 5px 0;
    opacity: 0.5;
}

.footer-top-container img.main-partner-logo:hover {
    opacity: 0.2;
}

.footer-top-container img:hover {
    opacity: 0.5;
}

.footer-bottom-container {
    background-color: transparent;
}

.footer-bottom-container .section.clearer {
    padding: 0;
}

.footer-copyright {
    color: #b3b3b3;
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1em;
    font-weight: 400;
}

/*Footer Copyright*/
.footer-copyright {
    display: none;
}

.copyright1 {
    background: transparent;
    text-transform: capitalize;
    font-family: 'Montserrat', sans-serif;
    letter-spacing: 0.5px;
    font-size: 1em;
    font-weight: 400;
    color: #b3b3b3;
}
/*Overwritten in footer.css*/
/*.footer-container {
    background-color: #3b4348;
    color: #fff;
}*/

/** extra **/
.item-user-links {
    display: none;
}

.header .logo strong {
    position:relative;
}
#root-wrapper span.fa {
    color:#aaa!important;
}
.header-top.header {
    text-align:right;
}
.module-category-filters .grid12-0, .module-header-multi .grid12-0 {
    vertical-align:top;
}

.module-header-multi {
    margin: 0;
    width: 100%;
}

.form-search .button {
    color:#aaa!important;
    z-index: 100;
}
/*Overwritten in header.css*/
/*.dropdown-toggle.cover>div {
    padding:0;
}*/
.nav-search.fly-out {
    margin:0;
}

.mm-panel_opened .mh-filters-btn {
    display: none !important;
}

@media only screen and (max-width:960px) {
    .nav-logo {
        width: 50%;
        float:left !important;
        vertical-align: middle;
        text-align:left;
        padding: 0.5em 0;
        line-height:1em;
        margin-left: 0 !important;
    }
    .logo img {
        max-height: 24px;
    }
    .display-mode-grid .sidebar-wrapper.mmenu-hide {
        display:block !important;
        margin-bottom: 0;
    }
    .display-mode-grid .sidebar-wrapper.mmenu-hide .sidebar-filter {
        display:none !important;
    }
    .module-header-multi .item {
        display:inline-block;
        float: none;
    }
    .module-header-multi .item .fa, #root-wrapper span.fa {
        font-size: 1.75em !important;
        padding: 0.25em !important;
        margin: 5px 0 !important;
    }
    .header .dropdown, .header-top {
        line-height: 1em !important;
    }
    .form-search .button-search {
        position: relative;
        font-size: inherit;
        padding: inherit;
    }
    
}
@media only screen and (max-width:767px) {
    #new-advanced-search-box .results-right .products ul li.grow {
        width:48% !important;
        padding:5px 0;
        text-align:center;
    }
    .header .logo {
        width: 100%;
    }
    .container {
        width: 96%;
        padding-left: 2%;
        padding-right: 2%;
    }
}

/* width > 960px */
@media only screen and (min-width: 960px) {
    /* Hide */
    .hide-above-960 { display:none !important; }
}