/*Contact us Override*/

.contact-form .form-list input.input-text, .contact-form .form-list textarea {
    width: 100%;
    background-color: #eee;
}
.contact-form button.button.left {
    margin-left: 15px;
} 
.contact-form .form-list label {
display: none;
}

.contact-form input::placeholder, .contact-form textarea::placeholder {
    color: #150604;
    font-family: "Work Sans",sans-serif;    
}
.contact-form .form-list input.input-text {
    height: 50px;
}
.contact-form .form-list .input-box {
    display: block;
    clear: both;
    padding: 1%;
}
.contact-form input::placeholder, .contact-form textarea::placeholder {
    color:#888;
    font-family:"Work Sans",sans-serif;
    font-size:14px;
}
.contact-form .fieldset, .info-set, .multiple-checkout .col2-set, .multiple-checkout .col3-set {
    padding: 0 8px 15px !important;
}