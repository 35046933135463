/*Product Detail Override*/
/*template from Kooka UK*/
.add-cart-fixed {
    display: none;
}

.product-shop h1 {
    font-size: 3em;
    font-family: "Work Sans", sans-serif !important;
    font-weight: 600 !important;
    line-height: 1em;
}
.product-shop .price-box .regular-price .price {
    font-size: 2em;
    line-height: 3;
}
.product-shop button.add-cart-button span {
    width: 100%;
    color: #fff;
    background-color: #111;
}

.product-shop .btn-cart span span {
    padding: 5px 20px;
    font-size: 1.1666em;
}

/*Product tab styling*/
.gen-tabs .tabs a.current {
    background-color: #fff;
    color: #000;
}
.gen-tabs-style1 .tabs a.current {
    background-color: #fff;
    border-color: transparent;
    border-bottom: none;
    margin-bottom: 0;
    font-family: "Work Sans", sans-serif;
    font-size: 2.4em;
    font-weight: 200;
    text-transform: capitalize;
}
.gen-tabs-style1 .tabs-panels {
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
}
caption, td, th {
    vertical-align: top;
    text-align: left;
    padding: 2%;
    font-size: 1.4em;
}
caption, th {
    font-weight: 600;
    background-color: #f5f5f5;
}
caption, td {
    font-weight: 200;
}
caption, table {
width: 100% !important;
}
span#regular-price.price {
    font-weight: 300;
}
