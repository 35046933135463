/*header Override*/
/*template from Kooka UK*/

/*user link style*/
.links>li>a {
    padding: 0 17px;
    color: #fff;
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 17px;
}
.links>li>a:hover {
    background-color: transparent;
}
@media only screen and (min-width: 960px) {
.user-link-wrapper {
    float: left;
}
}

/*search styling*/
.search-wrapper {
    margin-top: 8px;
    text-align: center;
    border-radius: 20px;
}
.module-search .form-search .input-text {
    width: 100%;
    border-radius: 20px;
    padding-left: 10%;
}
.module-search .form-search .button-search .fa {
    font-size: 16px;
    color: #000 !important;
    padding: 10px;
    font-style: normal;
}
@media only screen and (min-width: 960px) {
/*form search input text to be black*/
.search-wrapper .form-search .input-text:focus, .search-wrapper .form-search .input-text:hover {
    width: 100%;
    background-color: #fff;
    color: #000;
    transition: width 300ms ease-in-out;
    -moz-transition: width 300ms ease-in-out;
    -webkit-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    }
}
.form-search .button {
    position: absolute;
    left: 5px;
    top: 0;
    font-size: 1.375em;
    color: #fff;
    padding: .5em;
}

/*Nav Styling*/
.header-primary-container, .header-top-container, nav.nav-container, .nav-item.active {
    background-color: transparent;
}
.module-sub-categories-title.active, .nav-item.active>a, .nav-mobile .nav-item.level0>a:hover, .nav-regular .nav-item.level0:hover>a {
    color: #008bd8 !important;
    background-color: transparent;
}
.nav-item.active > a .caret {
    border-top-color: #008bd8 !important;
}
.nav-item.active {
    background-color: transparent;
}
.mobnav-trigger a, .nav-container a, .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a {
    color: #000;
}
.mobnav-trigger a, .nav-container a, .opt-fx-fade-inout.opt-sb2.xm-mega-menu-categories.xm-mega-menu.nav-regular a {
    color: #000;
    font-family: 'Work Sans';
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1em;
    width: 100%;
}
#nav {
    z-index: 1000;
    position: relative;
    display: flex;
    justify-content: center;
}
/*Dropdown menu*/
.nav-regular .mega>.nav-panel--dropdown, .nav-regular li.level0>.nav-panel--dropdown {
    border-top: none;
    width: 55%;
    margin-left: -5%;
}
.nav-regular .classic>.nav-panel--dropdown, .nav-regular .mega>.nav-panel--dropdown, .nav-regular li.level0>.nav-panel--dropdown {
    border-top: none;
}
#nav .nav-panel--dropdown {
    background-color: #3384C6;
    color: #fff;
}
#nav .nav-panel--dropdown a {
    color: #fff !important;
    font-size: 1em;
    line-height: 1.3;
}
#nav .nav-panel--dropdown a:hover {
    color: #c7c4bd !important;
}
#nav .nav-item.level0.parent:hover > a .caret {
    border-top-color: #c7c4bd;
}

/*Mini cart styling*/
#nav .nav-panel--dropdown a:hover, #root-wrapper a:hover span.fa:before, .form-list label.required em, .header .dropdown-menu a:hover, .header .form-search .search-autocomplete li:hover, .header .open>.dropdown-toggle.cover>div a:hover, .header-top a:hover, .product-options dt label.required em, .vertnav li.current>a, a:hover, p.required {
    color:#008bd8;
}
.dropdown-toggle.cover>div {
    padding: 0 5px;
    background-color: #fff;
    margin-top: 8px;
}
/*Smooth scroll for header*/
@media only screen and (min-width: 960px) {
    header#header.floating {
        display: block !important;
        position: sticky !important;
    }
}
div.hide-below-960 {
    display: none;
}
#mini-cart .feature-icon-hover a {
    color: #000;
    font-weight: 200;
    font-size: 1.4em;
}
.fa-shopping-cart:before {
    content: "\f07a";
    color: #000;
}
/*Logo*/
.header .logo {
    max-width: 225px;
    margin-top: -8px !important;
}
/*Breadcrumbs*/
#breadcrumbs li {
    display: inline-block;
}

#breadcrumbs a, #breadcrumbs a:hover, #breadcrumbs li.last-child {
text-transform: capitalize;
color: #a2a1a1;
font-weight: 300;
}

#breadcrumbs li.home a {
    color: #000;
    text-decoration: underline;
}
#breadcrumbs li.home:before {
content: "\f015";
font-family: "fontAwesome";
font-size: 1.4em;
vertical-align: super;
float: left;
margin-right: 5px;
color: #000;
}

#breadcrumbs li.home:hover:before {
color: #c7804d;
}
.breadcrumbs li span.breadcrumb-separator:before {
    content: "\f054";
    font-family: "fontAwesome";
    font-size: 1.4em;
    vertical-align: super;
    float: left;
    margin-right: 5px;
    color: #000;
}

#breadcrumbs li.home a:hover {
    color: #c7804d;
    text-decoration: none;
}
.details-main-container .breadcrumbs {
    margin-bottom: 10px;
    border-bottom: 3px solid #151515;
}

/*Responsive for smaller screens*/
@media only screen and (max-width: 960px) {
.module-sub-categories-title.active, .nav-item.active>a {
    color: #008bd8 !important;
    background-color: transparent !important;
    }
    /*.nav-cart .hide-below-960 .cart-total { 
    background-color: #008bd8 !important;
    }
    .dropdown-toggle.cover>div {
        padding: 0 5px;
        background-color: #fff;
        margin-top: 0;
        height: 50px !important;

    }*/
   
}