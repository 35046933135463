/*Categorypage Override*/
/*template from Kooka UK*/
/*Removing hover space change*/
.products-grid.single-line-name .product-name {
    overflow: visible;
    white-space: normal;
    font-weight: 300;
    text-transform: capitalize;
}

.price-box .minimal-price .price, .price-box .regular-price .price, .price-box-bundle .full-product-price .price {
    color: #000;
    font-weight: 600;
    letter-spacing: .5px;
    font-family: "Work Sans", sans-serif;
    font-size: 18px;
}
.products-grid .price-box {
    margin-top: 1.2em;
    font-size: 1em;
}

/*Button changes*/
button.button span {
    text-transform: uppercase;
    border-radius: 0;
    background-color: #008bd8;
    color: #fff;
}
.product-view .btn-cart:hover span, button.btn-checkout:hover span, button.button:hover span {
    background-color: #008bd8;
    color: #fff;
}
button.button.more-info {
    display: none;
}
/*font style changes*/
.products-grid .product-name, .products-list .product-name, h3.product-name {
    font-size: 14px;
    font-family: "Work Sans", sans-serif;
    font-weight: 300;
}

/*Filter Styling*/
.accordion-style1 li a:active,.accordion-style1 li a:hover {
    background-color: #fbd685;
    color: #c7804d;
    font-weight: 600;
    font-family: "Work Sans", sans-serif;
    font-size: 18px;
    text-transform: capitalize;
}
/*.accordion-style1 li a:hover {
    background-color: transparent;
    color: #c7804d;
    font-weight: 300;
    font-size: 18px;
    text-transform: capitalize;
}*/
.accordion-style1 li a, .sidebar a {
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 300;
}

/*Product Category Page*/
/*.product-category-title {
    width: 15%;
}*/
.product-category-title img {
    width: 100% !important;
    height: auto !important;
}
.product-category-title h3 {
    background: #008cd9;
    color: #fff!important;
    padding: 15% 10%;
    text-transform: capitalize;
    margin-top: -12px !important;
    font-weight: 300;
    height: 50px;
    text-align: center;
    line-height: 1.4;

}
.product-category-title h3 a {
    color: #fff!important;
}

/*category filter sidebar styling*/
.accordion-style1 li a, .sidebar a {
    text-transform: capitalize !important;
}


/*Center category images on product category page*/
.product-category {
    display: flex;
    justify-content: center;
}
/*small width for category container*/
@media only screen and (min-width:960px) {
    .width-70 .nested-container {
     width: 70%;
    margin: auto;
    }
    .width-52 .nested-container {
        width: 52%;
       margin: auto;
       }
 }

 .category-title {
     text-align: center;
     border: none;
 }

/*remove scroll on smaller screen size*/
.col1-layout .col-main, .main-container .top-banner {
    position: relative;
    display: inline-block;
    overflow: hidden;
}
    
@media only screen and (max-width: 960px) { 
.category-filter-small {
    display: block;
    }
    .category-filter {
        display: none;
    }
    .sidebar-category .block-content {
        overflow: hidden !important;
    }
}
@media only screen and (min-width: 961px) {
    .category-filter-small {
        display: none;
        }
    .category-filter {
        display: block;
        }
}
@media only screen and (max-width: 960px) {
    .sidebar-category .accordion li {
    display: block !important;
    width: auto!important;
    }
    .sidebar-category .accordion li {
    float: none;
    }
}
