/*Cart Override*/
/*template from Kooka UK*/


#shopping-cart-table .product-name .description {
    display: block;
    text-transform: capitalize;
    font-size: 0.6em;
    text-decoration: underline;
    font-weight: 500;
}
.cart-table thead tr, .data-table thead, .data-table tr.odd td, .data-table tr.odd th {
    border-bottom: none;
    border-right:none;
}
.data-table tr.odd.last {
    border-bottom: #000 solid 3px;
    background-color: #fcfcfc;
}
#shopping-cart-table {
    border-top: #000 solid 5px;
}
#shopping-cart-table th {
    font-weight: 600;
    background-color: #f5f5f5;
}
.data-table td, .data-table th {
    padding: 2%;
}
.cart-table .product-name a:hover {
    color: #c7804d;
}
.cart-action-buttons .btn-inline {
    padding: 15px;
}
.button.btn-checkout span, .button.btn-inline span, .buttons-set button.button span {
    background-color: #c7804d !important;
    color: #fff;
    text-transform: capitalize;
    font-size: 1.2em;
    padding: 0.5em !important;
}
.button.btn-checkout span, .button.btn-inline span:hover {
    background-color: #ae6734;
    color: #fff;
}
.buttons-set button.button span {
    background-color: #c7804d;
    color: #fff;
}

/*cart totals*/
.a-right {
    text-align: left!important;
}
thead.cart-summary-title {
    font-weight: 600;
    margin-top: 1.5em;
    font-size: 1.618em;
    border-bottom: 3px solid #000;

}
thead.cart-summary-title tr th {
    background-color: transparent;
    padding: 2% 0;
}
.cart .totals td {
    padding: 3% !important;
    background-color: #f8f8f8;
    border-bottom: 1px dashed #e1e1e1;
}
#shopping-cart-totals-table td[colspan] {
    background-color:#f5f5f5;
 font-weight: 400;
   border-bottom: 1px dashed #e1e1e1;
}
#shopping-cart-totals-table .grand-total-row {
    border-bottom: 3px solid #000;
}
.additional-bg-color, .block-layered-nav .currently, .cart .totals {
    background-color: transparent;
}
ul.checkout-types button.btn-proceed-checkout.btn-checkout, ul.checkout-types button.btn-proceed-checkout.btn-checkout span {
    width: 100%;
}
ul.checkout-types button.btn-proceed-checkout.btn-checkout span {
padding: 1% 0 !important;
background-color: #000;
}