/*Footer Override*/
/*template from Kooka UK*/

.footer-container {
    background-color: transparent;
    color: #fff;
}
.footer-container {
    background-color: transparent;
    color: #fff;
}