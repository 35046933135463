/*Mobile Styling*/

/*Mobile hide to replace hide-below-960 styling since that hides on desktop as well.*/

@media only screen and (max-width:479px) {
    .mobile-hide {
        display:none;
   }
    .mobile-show .dropdown-toggle.cover>div {
        margin-top:0;
   }
    .mobile-show .about-content-home > div.std .nested-container {
        width:80%;
   }
    .homepage-category {
        height:auto !important;
   }
   /*Minicart styling*/
   .nav-cart .hide-below-960 .cart-total {
    background-color: #008bd8 !important;
   }
   .about-content-home .nested-container {
       width: 80% !important;
   }
   /*Footer Styling*/
   .footer-top-container {
    margin-top: 0 !important;
   }
   .footer-primary-container {
    background-color: transparent;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    }
}
    /*Footer icon styling*/
    @media only screen and (max-width:479px) {
        .footer-account .nav-logo, .footer-account .nav-search, .footer-account .nav-cart {
            display:none !important;
       }
        .mmenu-wrapper {
            display: inline-block !important;
            float: left;
       }
        .footer-search .form-search .input-text, .footer-cart .nav-cart, .footer-cart .nav-logo, .footer-cart .nav-search {
            display: none !important;
       }
        .footer-account, .mmenu-wrapper, .footer-search, .footer-cart {
            display:inline-block;
            width:31.33% !important;
       }
        .footer-account, .mmenu-wrapper, .footer-cart {
            height:auto !important;
       }
        .footer-primary-bottom-spacing {
            padding-top:0;
            padding-bottom:0;
       }
        #mini-cart {
            float:none;
       }
        .module-search .form-search .button-search {
            position:inherit !important;
       }
        .dropdown-toggle.cover>div {
            margin-top:0;
       }
        .fa-search:before {
            content:"\f002";
            font-size:1.2em!important;
       }
       /*Category page*/
       .sidebar-category .accordion {
        overflow-x: scroll;
        width: 100% !important;
       }
       /*Product page*/
       /*reduce image height*/
       p.product-image.zoom-inside {
        max-width: 250px !important;
       }
       .product-shop {
        margin-top: 0;
       }
        .mobile-tabs .nested-container, .cart-summary-mobile .nested-container {
        width:100% !important;
       }
        .gen-tabs.accor .tabs-panels .panel {
        padding:0;
       }
       .product-shop h1 {
        font-size: 2.5em !important;
       }
       .product-shop .product-type-data, .product-shop h1 {
        text-align: left !important;
       }
       /*align cart and qty field in mobile*/
       .product-shop .qty-wrapper input {
        font-size: 1.2em;
        min-width: 100px !important;
        width: 0 !important;
        height: 55px;
        margin-top: -8px;
        padding: 0;
       }
       .product-shop .btn-cart span span {
        padding: 5px 20px !important;
       }
       
       /*Cart*/
       thead.cart-summary-title {
           font-size: 1.3em;
       }
       .cart .totals-inner {
           padding: 0;
       }
       .cart-action-buttons .btn-inline {
        padding: 2%;
       }
        .button.btn-checkout span, .button.btn-inline span, .buttons-set button.button span {
        padding: 0 10px !important;
       }
       /*Checkout*/
       .page-type-cart .accordion-style1 li a, .sidebar a {
           font-size: 12px;
       }
       .product-shop .module-product-details #add-to-cart-block {
           text-align: center;
       } 
}

@media only screen and (min-width:480px) {
    .mobile-hide {
        display:block;
   }
}